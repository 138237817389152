import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/cookie-policy.module.css";

const CookiePolicy = () => {
  return (
    <Layout title={"Cookie Policy"}>
      <article>
        <HeaderTitle
          overline={"Latest update: June 08, 2022"}
          titleWidth={"13em"}
        >
          Cookie Policy of Userbrain
        </HeaderTitle>

        <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

        <div className={helperStyles.container}>
          <HtmlContent
            className={cn(
              helperStyles.spacingMd,
              helperStyles.widthHtmlContent
            )}
          >
            <h2>1. General</h2>
            <p>
              When using our website{" "}
              <a href={"https://www.userbrain.com"}>
                https://www.userbrain.com
              </a>
              , so called cookies are used to ensure satisfactory website use
              and use of Userbrains services. A cookie is a small text file
              downloaded on the hard drive of your device via your browser.
              Cookies serve the purpose of enabling the correct functioning of
              Userbrains website, expanding the functional scope of the website
              and realizing an optimization of its functions. In addition,
              cookies may also be used to collect statistical data and for
              marketing purposes.
            </p>
            <h2>2. Data Controller</h2>
            <p>
              <strong>Userbrain GmbH</strong>
              <br />
              Frauengasse 7<br />
              8010 Graz
              <br />
              AUSTRIA
              <br />
              email:{" "}
              <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>
            </p>
            <h2>3. Types of Data collected</h2>
            <p>Cookies may contain the following data:</p>
            <ol className={styles.orderedListCookie}>
              <li>
                Log files: e.g. IP addresses; browser type, browser version,
                used OS, referrer URL, hostname of the accessing computer, date
                and time of the server request
              </li>
              <li>
                Usage data: e.g. IP addresses, URI addresses, the time of the
                request, the method utilized to submit the request to the
                server, the size of the file received in response, the numerical
                code indicating the status of the server's answer (successful
                outcome, error, etc.), the country of origin, the features of
                the browser and the operating system utilized by the User, the
                various time details per visit (e.g., the time spent on each
                page within the Application) and the details about the path
                followed within the Application with special reference to the
                sequence of pages visited
              </li>
            </ol>
            <p>
              More information about the collected personal data is provided in
              the dedicated sections of this cookie policy or by specific
              explanation texts displayed prior to the data collection.
            </p>
            <h2>4. Data Transfer to Third countries</h2>
            <p>
              Some of the collected data will be transferred to third countries
              (i.e. outside the EU and the EEA).
            </p>
            <p>
              In case the operator of a third party cookie is located in the USA
              or Australia, your personal data will be transferred to the USA or
              to Australia. There is currently no adequacy decision by the EU
              Commission for the USA or for Australia in accordance with Article
              45 Paragraph 3 leg cit. This means that your data will be
              transferred to a country that does not offer an adequate level of
              protection in terms of security of your personal data compared to
              the EU. Which data importer in the USA or in Australia your data
              is transmitted to depends on which services are used. These
              services are listed below.
            </p>
            <p>
              The transfer of your personal data to the USA or to Australia
              involves the following risks: (i) your personal information may be
              disclosed by the data importer; (ii) Authorities conducting
              surveillance programs, among other things, could gain access to
              your personal data; (iii) your legal remedies are limited in
              certain circumstances; (iv) the technical and organizational
              measures taken by the data importer to protect personal data may
              not fully meet the quantitative and qualitative requirements of
              the GDPR.
            </p>

            <h2>5. Detailed information on the use of cookies</h2>
            <p>
              A detailed description of the cookies used by Userbrain is set out
              below. If you have any questions, please contact{" "}
              <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>
              .
            </p>
            <h3>5.1. SPAM protection – Google reCAPTCHA</h3>
            <p>
              This type of service analyzes the traffic of Userbrain,
              potentially containing users' personal data, with the purpose of
              filtering it from parts of traffic, messages and content that are
              recognized as SPAM. This cookie ist necessary for operating our
              website and can therefore not be disabeld. If users do not want
              userbrain to set this cookie, please do not visit our website.
            </p>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>
              <li>
                Name and contact information:
                <br />
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                Ireland
                <br />
                e-mail:{" "}
                <a href={"mailto:support-at@google.com"}>
                  support-at@google.com
                </a>
              </li>
              <li>
                Purpose of the processing activity:
                <br />
                Google reCAPTCHA is a SPAM protection service provided by Google
                and is used to identify spam and to protect Userbrain against
                spam and cyberattacks.
              </li>
              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>
              <li>
                Legal Basis:
                <br />
                Data processing is based on the legal ground of legitimate
                interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate
                interest to procetct its website against spam and cyberattacks.
                Therefore your consent is not requestet.
              </li>
              <li>
                Name of the cookie and Storage duration:
                <br />
                This cookie is a session cookie and is deleted after the session
                ends. _GRECAPTCHA: duration of the session
                <ul>
                  <li>rc::a: indefinite</li>
                  <li>rc::b: duration of the session</li>
                  <li>rc::c: duration of the session</li>
                </ul>
              </li>
              <li>
                Further information:
                <br />
                The use of reCAPTCHA is subject to the Google privacy policy and
                terms of use.
              </li>
            </ol>

            <h3>5.2. Interaction with live chat platforms by Olark Widget</h3>
            <p>
              Userbrain uses cookies to enable basic interactions and
              functionalities, allowing users to access selected features of the
              service and facilitating the user's communication with the
              controller.
            </p>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>
              <li>
                Name and contact information:
                <br />
                Habla Inc. (dba Olark), 427 N Tatnall St 63602 Wilmington,
                Delaware 19801, USA
                <br />
                e-mail:{" "}
                <a href={"mailto:privacy@olark.com"}>privacy@olark.com</a>
              </li>
              <li>
                Purpose of the processing activity:
                <br />
                The Olark Widget is a service for interacting with the Olark
                live chat platform provided by Habla, Inc. This type of service
                allows users to interact with third-party live chat platforms
                directly from the pages of Userbrain, for contacting and being
                contacted by Userbrain support service.
              </li>
              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>
              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>
              <li>
                Transfer of data to third countries:
                <br />
                USA
              </li>
              <li>
                Further information:
                <br />
                <a href={"https://www.olark.com/privacy-policy"}>
                  https://www.olark.com/privacy-policy
                </a>
              </li>
            </ol>

            <h3>5.3. Displaying content from external platforms</h3>
            <p>
              This type of service allows you to view content hosted on external
              platforms directly from the pages of Userbrain and interact with
              them.
              <br />
              This type of service might still collect web traffic data for the
              pages where the service is installed, even when Users do not use
              it.
            </p>

            <h4>5.3.1 Displaying content by Wistia widget</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>
              <li>
                Name and contact information:
                <br />
                Wistia, Inc., 120 Brookline Street, Cambridge, Massachusetts
                02139, USA
                <br />
                e-mail:{" "}
                <a href={"mailto:privacy@wistia.com"}>privacy@wistia.com</a>
              </li>
              <li>
                Purpose of the processing activity:
                <br />
                Wistia is a video content visualization service provided by
                Wistia, Inc. that allows Userbrain to incorporate content of
                this kind on its pages.
              </li>
              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>
              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>
              <li>Transfer of data to third countries: USA</li>
              <li>
                Further information:
                <br />
                <a href={"https://wistia.com/privacy"}>
                  https://wistia.com/privacy
                </a>
              </li>
            </ol>

            <h4>Displaying content by Vimeo video</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>
              <li>
                Name and contact information:
                <br />
                Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA
                <br />
                e-mail:{" "}
                <a href={"mailto:privacy@vimeo.com"}>privacy@vimeo.com</a>
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                Vimeo is a video content visualization service provided by
                Vimeo, LLC that allows Userbrain to incorporate content of this
                kind on its pages.
              </li>

              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie and Storage duration:
                <ul>
                  <li>player: 1 year</li>
                  <li>vuid: 2 years</li>
                </ul>
              </li>

              <li>
                Transfer of data to third countries:
                <br />
                USA
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://vimeo.com/privacy"}>
                  https://vimeo.com/privacy
                </a>
              </li>
            </ol>

            <h3>5.4. Analytics</h3>
            <p>
              For the statistical analysis of the website and the services
              Userbrain uses cookies to measure traffic and analyze user
              behavior with the goal of improving the service.
            </p>

            <h4>5.4.1 HubSpot Analytics</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                HubSpot, Inc., 25 First Street, 2nd Floor, Cambridge,
                Massachusetts 02141, USA
                <br />
                e-mail:{" "}
                <a href={"mailto:privacy@hubspot.com"}>privacy@hubspot.com</a>
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                HubSpot Analytics is used to analyse marketing campaigns.
              </li>

              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie Storage duration:
                <br />
                <ul>
                  <li>__hssc: 1 hour</li>
                  <li>__hssrc: duration of the session</li>
                  <li>__hstc: 2 years</li>
                  <li>hubspotutk: 2 years</li>
                  <li>messagesUtk: 2 years</li>
                </ul>
              </li>

              <li>
                Transfer of data to third countries:
                <br />
                USA
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://legal.hubspot.com/de/privacy-policy"}>
                  https://legal.hubspot.com/de/privacy-policy
                </a>
              </li>
            </ol>
            <h4>5.4.2 Twitter Ads conversion tracking</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                Twitter International Unlimited Company, One Cumberland Place,
                Fenian Street, Dublin 2, D02 AX07 Ireland
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                Twitter Ads conversion tracking is an analytics service provided
                by Twitter that connects data from the Twitter advertising
                network with actions performed on Userbrain. This cookie is used
                to analyse marketing campaings.
              </li>

              <li>
                Personal Data processed:
                <br />
                Log Files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie and Storage duration:
                <br />
                <ul>
                  <li>personalization_id: 2 years</li>
                </ul>
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://twitter.com/en/privacy"}>
                  https://twitter.com/en/privacy
                </a>
              </li>
            </ol>
            <h4>5.4.3 Google Ads conversion tracking</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                Ireland e-mail:{" "}
                <a href={"mailto:support-at@google.com"}>
                  support-at@google.com
                </a>
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                Google Ads conversion tracking is an analytics service provided
                by Google Ireland Limited that connects data from the Google Ads
                advertising network with actions performed on Userbrain. This
                cookie is used to analyse marketing campaings.
              </li>

              <li>
                Personal Data processed:
                <br />
                Log Files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie and Storage duration:
                <br />
                <ul>
                  <li>IDE: 2 years</li>
                  <li>test_cookie: 1 hour</li>
                </ul>
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://policies.google.com/privacy"}>
                  https://policies.google.com/privacy
                </a>
              </li>
            </ol>
            <h4>5.4.4 Google Analytics with anonymized IP</h4>
            <p>
              Google Analytics is a web analysis service provided by Google
              Ireland Limited. Google utilizes the Data collected to track and
              examine the use of Userbrain, to prepare reports on its activities
              and share them with other Google services. Google may use the Data
              collected to contextualize and personalize the ads of its own
              advertising network.This integration of Google Analytics
              anonymizes your IP address. It works by shortening Users' IP
              addresses within member states of the European Union or in other
              contracting states to the Agreement on the European Economic Area.
              Only in exceptional cases will the complete IP address be sent to
              a Google server and shortened within the US.
            </p>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                Ireland
                <br />
                e-mail:{" "}
                <a href={"mailto:support@google.com"}>support@google.com</a>
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                Google Analytics is used to analyse marketing campaings.
              </li>
              <li>
                Personal Data processed:
                <br />
                Log Files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie and Storage duration:
                <br />
                <ul>
                  <li>AMP_TOKEN: 1 hour</li>
                  <li>__utma: 2 years</li>
                  <li>__utmb: 30 minutes</li>
                  <li>__utmc: duration of the session</li>
                  <li>__utmt: 10 minutes</li>
                  <li>__utmv: 2 years</li>
                  <li>__utmz: 7 months</li>
                  <li>_ga: 2 years</li>
                  <li>_gac*: 3 months</li>
                  <li>_gat: 1 minute</li>
                  <li>_gid: 1 day</li>
                </ul>
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://policies.google.com/privacy"}>
                  https://policies.google.com/privacy
                </a>
              </li>
            </ol>
            <h3>5.5. Heat mapping and session recording (Microsoft Clarity)</h3>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>
              <li>
                Name and contact information:
                <br />
                Microsoft Ireland Operations Limited, Z. Hd.: Data Protection
                Officer, One Microsoft Place, South County Business Park,
                Leopardstown, Dublin 18, Irland.
                <br />
                <a href={"https://www.microsoft.com/de-at/concern/privacy"}>
                  https://www.microsoft.com/de-at/concern/privacy
                </a>
              </li>
              <li>
                Purpose of the processing activity:
                <br />
                Heat mapping services are used to display the areas of a page
                where users most frequently move the mouse or click. This shows
                where the points of interest are. These services make it
                possible to monitor and analyse web traffic and keep track of
                user behaviour. Some of these services may record sessions and
                make them available for a later visual playback. Microsoft
                Clarity is also a remarketing and behavioral targeting service
              </li>
              <li>
                Personal Data processed:
                <br />
                Log files and Usage Data
              </li>
              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>
              <li>
                Further information:
                <br />
                <a
                  href={"https://privacy.microsoft.com/de-de/privacystatement"}
                >
                  https://privacy.microsoft.com/de-de/privacystatement
                </a>
              </li>
            </ol>
            <h3>5.6. Targeting & Advertising</h3>
            <p>
              Userbrain uses cookies to deliver personalized marketing content
              based on user behavior and to operate, serve and track ads.
            </p>
            <p>
              This type of service allows Userbrain to inform, optimize and
              serve advertising based on past use of Userbrain by the user. This
              activity is facilitated by tracking usage data and by using
              cookies to collect information. Some services offer a remarketing
              option based on email address lists.
            </p>

            <h4>5.6.1 Twitter Remarketing</h4>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                Twitter International Unlimited Company, One Cumberland Place,
                Fenian Street, Dublin 2, D02 AX07 Ireland
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                Twitter Remarketing is a remarketing and behavioral targeting
                service provided by Twitter that connects the activity of
                Userbrain with the Twitter advertising network.
              </li>

              <li>
                Personal Data processed:
                <br />
                Log files and usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Name of the cookie and Storage duration:
                <br />
                <ul>
                  <li>personalization_id: 2 years</li>
                </ul>
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://twitter.com/en/privacy"}>
                  https://twitter.com/en/privacy
                </a>
              </li>
            </ol>

            <h3>5.7. Google Tag Manager</h3>
            <ol className={styles.orderedListCookie}>
              <li>
                Cookie type:
                <br />
                Third party cookie
              </li>

              <li>
                Name and contact information:
                <br />
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                Ireland e-mail:{" "}
                <a href={"mailto:support-at@google.com"}>
                  support-at@google.com
                </a>
              </li>

              <li>
                Purpose of the processing activity:
                <br />
                This type of service helps the Controller to manage the tags or
                scripts needed on Userbrain in a centralized fashion. This
                results in the Users' data flowing through these services,
                potentially resulting in the retention of this data.
              </li>

              <li>
                Personal Data processed:
                <br />
                Usage data
              </li>

              <li>
                Legal Basis:
                <br />
                Consent of the data subject Art 6 (1) (a) GDPR
              </li>

              <li>
                Further information:
                <br />
                <a href={"https://policies.google.com/privacy"}>
                  https://policies.google.com/privacy
                </a>
              </li>
            </ol>

            <h2>6. Rights of users</h2>
            <p>
              You will find a description of your rights in the privacy policy
              of Userbrain.
            </p>

            <h2>7. Definitions and legal references</h2>
            <p>
              Where this document uses the terms defined in Regulation (EU)
              2016/679, those terms shall have the same meaning as in that
              Regulation.
            </p>
          </HtmlContent>
        </div>
      </article>
    </Layout>
  );
};

export default CookiePolicy;
